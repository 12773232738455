<template>
  <div></div>
</template>
<script>
export default {
  created() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // thực hiện logout
    this.logout(params.url);
  },
  methods: {
    async logout(url) {
      try {
        this.$store.dispatch("loading");
        await this.$store.dispatch("user/logout");
      } catch (e) {
        console.log(e)
      } finally {
        this.$store.dispatch("loading", false);
        if (url) {
          location.href = url;
        } else {
          location.href = "/";
        }
      }
    },
  },
};
</script>
